<template>
  <v-data-table
    no-data-text="Nenhum dado encontrado"
    :headers="headers"
    :items="data"
    item-key="id"
    sort-by="id"
  >
    <template v-slot:[`item.menu`]="{ item }">
      <v-btn class="elevation-0" icon @click="open(item.id)">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
// import Excel from "../../components/Excel/Excel.vue";

export default {
  props: {
    data: { required: true, type: Array },
  },
  components: {
    // Excel,
  },
  data() {
    return {
      attendance: [],
      dialog: false,
    };
  },
  methods: {
    open(id) {
      this.$emit("open", id);
    },
  },
  computed: {
    headers() {
      return [
        { text: "Paciente", value: "patient.name" },
        { text: "Descrição", value: "subject_matter" },
        { text: "Responsável", value: "responsible.name" },
        { text: "Data e Horario do alerta", value: "created_at" },
        { text: "Ação", value: "menu", width: "10%"},
      ];
    },
  },
};
</script>

<style>
.iconS {
  margin-bottom: 20%;
}
</style>
