<template>
  <v-row class="pa-5">
    <!-- info diailog -->
    <v-dialog
      v-if="dialog.open"
      max-width="100vw"
      v-model="dialog.open"
      persistent
      :key="dialog.item.id"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5 pb-3">Detalhes do atendimento</span>
        </v-card-title>
        <v-card-subtitle>
          <span class="text">Informações sobre o atendimento do alerta</span>
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <label class="text">Atendente responsável</label>
                <v-text-field
                  v-model="dialog.item.responsible.name"
                  outlined
                  disabled
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <label class="text">Data e horário do atendimento</label>
                <v-text-field
                  v-model="dialog.item.created_at"
                  outlined
                  disabled
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <label class="text">Tipo do alerta</label>
                <v-text-field
                  :value="dialog.item.alert.risk.text"
                  outlined
                  disabled
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <label class="text">Motivo do alerta</label>
                <v-text-field
                  v-model="dialog.item.alert.status.text"
                  outlined
                  disabled
                >
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="4" md="4">
                <label class="text">Parâmetro</label>
                <v-text-field
                  v-model="dialog.item.alert.alertable.value"
                  outlined
                  disabled
                >
                </v-text-field>
              </v-col> -->
              <v-col cols="12" sm="12" md="12">
                <label class="text">Descrição do pedido</label>
                <v-textarea
                  v-model="dialog.item.subject_matter"
                  outlined
                  disabled
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>* indica campo obrigatório!</small>
          <v-row >
            <v-spacer></v-spacer>
            <v-btn class="btn white--text" text @click="dialog.open = false">
              Finalizar atendimento
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- loading -->
    <Overlay :loading="loading" />
    <!-- main page -->
    <v-col class="pl-1" cols="12">
      <v-btn class="pl-0" text @click="$router.go(-1)">
        <v-icon class="pr-1">mdi-chevron-left</v-icon>
        Voltar
      </v-btn>
      <v-col cols="12" class="mt-3">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters>
              <span class="text-h4"> Alarmes atendidos </span>
            </v-row>
            <v-row no-gutters>
              <span class="caption font-weight-light pt-0 pb-0">
                Lista de chamados realizados pelos atendentes
              </span>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <FinishedDialog :data="attendance || []" @open="openDialog" />
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import FinishedDialog from "../../../components/Manager/FinishedDialog.vue";
import Overlay from "../../../components/Overlay/Overlay.vue";

export default {
  components: { FinishedDialog, Overlay },
  data() {
    return {
      attendance: [],
      patient: {},
      dialog: { open: false, item: {} },
      loading: false,
    };
  },
  mounted() {
    this.getAttendances();
  },
  methods: {
    async openDialog(id) {
      this.dialog = {
        open: true,
        item: this.attendance.find((item) => item.id == id),
      };
    },
    formatLastUpdate(date) {
      if (!date) return "";

      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    async getAttendances() {
      this.loading = true;
      try {
        const response = await this.$axios("attendances");

        if (response.data) {
          response.data.map(
            (el) => (el.created_at = this.formatLastUpdate(el.created_at))
          );

          this.attendance = response.data;
        }

        if (response.data) {
          response.data.forEach(
            (el) => (el.alert.risk = this.setType(el.alert.risk))
          );
        }

        if (response.data) {
          response.data.forEach(
            (el) => (el.alert.status = this.setStatus(el.alert.status))
          );
        }

        // if (response.data) {
        //   response.data.map((el) => {
        //     if (el.alert.status.value == "temperature_rose") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.real_temperature
        //       ));
        //     } else if (el.alert.status.value == "temperature_dropped") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.real_temperature
        //       ));
        //     } else if (el.alert.status.value == "beats_dropped") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.bpm
        //       ));
        //     } else if (el.alert.status.value == "beats_rose") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.bpm
        //       ));
        //     } else if (el.alert.status.value == "dbp_dropped") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.dbp
        //       ));
        //     } else if (el.alert.status.value == "dbp_rose") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.dbp
        //       ));
        //     } else if (el.alert.status.value == "sbp_dropped") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.sbp
        //       ));
        //     } else if (el.alert.status.value == "sbp_rose") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.sbp
        //       ));
        //     } else if (el.alert.status.value == "blood_oxygen_dropped") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.blood_oxygen
        //       ));
        //     } else if (el.alert.status.value == "blood_oxygen_rose") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.blood_oxygen
        //       ));
        //     } else if (el.alert.status.value == "total_sleep_dropped") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.real_temperature
        //       ));
        //     } else if (el.alert.status.value == "total_sleep_rose") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.real_temperature
        //       ));
        //     } else if (el.alert.status.value == "falldown_detected") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.real_temperature
        //       ));
        //     } else if (el.alert.status.value == "sos_press") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.real_temperature
        //       ));
        //     } else if (el.alert.status.value == "sos_press") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.real_temperature
        //       ));
        //     } else if (el.alert.status.value == "fence_broken") {
        //       return (el.alert.alertable = this.setAlertable(
        //         el.alert.alertable.real_temperature
        //       ));
        //     }
        //   });
        // }
        this.loading = false;
        console.log(response.data);
      } catch (error) {
        this.loading = false;
        console.log("mensagem de erro: " + error);
      }
    },
    setStatus(value) {
      const status = {
        temperature_dropped: {
          text: "Temperatura baixa",
          value: "temperature_dropped",
        },
        temperature_rose: {
          text: "Temperatura alta",
          value: "temperature_rose",
        },
        beats_dropped: {
          text: "Batimento baixo",
          value: "beats_dropped",
        },
        beats_rose: {
          text: "Batimentos Elevado",
          value: "beats_rose",
        },
        dbp_dropped: {
          text: "DBP baixo",
          value: "dbp_dropped",
        },
        dbp_rose: {
          text: "DBP Alto",
          value: "dbp_rose",
        },
        sbp_dropped: {
          text: "SBP baixo",
          value: "sbp_dropped",
        },
        sbp_rose: {
          text: "SBP Alto",
          value: "sbp_rose",
        },
        blood_oxygen_dropped: {
          text: "Oxigênação baixa",
          value: "blood_oxygen_dropped",
        },
        blood_oxygen_rose: {
          text: "Oxigenação alta",
          value: "blood_oxygen_rose",
        },
        total_sleep_dropped: {
          text: "Sono baixo",
          value: "total_sleep_dropped",
        },
        total_sleep_rose: {
          text: "Sono Alto",
          value: "total_sleep_rose",
        },
        falldown_exam: {
          text: "Alerta de queda",
          value: "falldown_exam",
        },
        sos_exam: {
          text: "Botão de S.O.S pressionado",
          value: "sos_exam",
        },
        fence_out_exam: {
          text: "Cerca Quebrada",
          value: "fence_out_exam",
        },
        fence_in_exam: {
          text: "Area Limite",
          value: "fence_in_exam",
        },
        low_battery_exam: {
          text: "Dispositivo descarregando",
          value: "low_battery_exam",
        },
        wifi_in_exam: {
          text: "Dentro da cerca de wifi",
          value: "wifi_in_exam",
        },
        wifi_out_exam: {
          text: "Fora da cerca de wifi",
          value: "wifi_out_exam",
        },
        device_offline_exam: {
          text: "Dispositivo offline",
          value: "device_offline_exam",
        },
        sedentary_exam: {
          text: "Tempo ocioso",
          value: "sedentary_exam",
        },
      };

      return (
        status[value] || {
          text: "Motivo não encontrado",
        }
      );
    }, 
    setType(value) {
      console.log(value)
      const type = {
        low: {
          text: "Abaixo do Parâmetro",
          value: '0',
        },
        high: {
          text: "Acima do Parâmetro",
          value: '1',
        },
        recurrent: {
          text: "Alerta recorrente",
          value: "2",
        },
      };

      return (
        type[value] || {
          text: "Não foi possivel identificar.",
        }
      );
    },
    setAlertable(value) {
      const alertable = {
        [value]: {
          text: `${value}`,
          value: [value],
        },
      };
      return (
        alertable[value] || {
          text: "Não foi possivel identificar.",
        }
      );
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
  },
};
</script>

<style>
.text {
  font-size: 16px;
}
.btn {
  background-color: #1c3b7b;
  margin-bottom: 2vh;
}
</style>
